import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import formatDate from "../../utilities/dateFormate";

import useStore from "../../store/store";
import PollModal from "./pollModal";
import PollRow from "./pollsDataRow";

const renderTooltip = (props, poll) => {
  const tooltipData = {
    "privacy:": poll?.privacy,
    "category:": poll?.category,
    "sub category:": poll?.subcategory,
    "start date:": formatDate(poll.startDate),
    "end date:": formatDate(poll.endDate),
    ...(poll?.privacy === "private" && { "voters:": poll?.votersList }),
    "casted votes:": poll?.voters,
  };

  return (
    <Tooltip {...props}>
      <div className="m-0 p-0">
        {Object.entries(tooltipData).map(([label, value], index) => (
          <p key={index} className="d-flex justify-content-between">
            <strong>{label} </strong>
            <span>{value}</span>
          </p>
        ))}
      </div>
    </Tooltip>
  );
};

function PollsData({ data }) {
  const { polls } = data;

  const {
    searchQuery,
    selectedCategory,
    selectedEndDate,
    setTotalPolls,
    setTotalCategories,
    tag,
  } = useStore();

  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedPoll, setSelectedPoll] = useState(null);

  const filteredPolls = polls.filter((poll) =>
    poll.tag.some((pollTag) => tag.includes(pollTag))
  );

  useEffect(() => {
    if (polls) {
      setTotalPolls(polls?.length);
      const uniqueCategories = [
        ...new Set(polls?.map((item) => item.category)),
      ];
      setCategories(uniqueCategories);
    }
    setTotalCategories(categories?.length);
  }, [polls, categories]);

  const check = filteredPolls.length > 0;

  const handleVoteClick = (pollId) => {
    navigate(`/vote/?vote=${pollId}`);
  };

  const handleShow = (poll) => {
    setSelectedPoll(poll);
    setShowModal(true);
  };

  return (
    <Container style={{ marginBlock: "2rem" }}>
      <Row className="fw-bold mb-2">
        <Col className="col-1">Privacy</Col>
        <Col className="col-4 me-1">All Polls</Col>
        <Col>Category</Col>
        <Col>Deadline</Col>
        <Col>Status</Col>
      </Row>
      {check
        ? filteredPolls.map((poll) => (
            <PollRow
              key={poll._id}
              poll={poll}
              handleVoteClick={handleVoteClick}
              handleShow={handleShow}
              renderTooltip={renderTooltip}
            />
          ))
        : polls
            .filter(
              (poll) =>
                poll.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
                (selectedCategory === "" ||
                  poll.category === selectedCategory) &&
                (selectedEndDate === "" ||
                  formatDate(poll.endDate) === selectedEndDate)
            )
            .map((poll) => (
              <PollRow
                key={poll._id}
                poll={poll}
                handleVoteClick={handleVoteClick}
                handleShow={handleShow}
                renderTooltip={renderTooltip}
              />
            ))}

      {selectedPoll && (
        <PollModal
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          selectedPoll={selectedPoll}
        />
      )}
    </Container>
  );
}

export default PollsData;
