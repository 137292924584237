import React, { useEffect } from "react";
import { Container, Form, Nav, Navbar, Button } from "react-bootstrap";
import logoutIcon from "../assets/svgs/logoutIcon.svg";
import mypoll from "../assets/svgs/mypoll.svg";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import useStore from "../store/store";

export default function MyPollNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setSearchQuery, searchQuery, signUpUserDetails } = useStore();

  const isLoggedIn = () => {
    return localStorage.getItem("token") ? true : false;
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    setSearchQuery("");
  }, []);

  const shouldRenderSearch =
    location.pathname === "/mypolls" || location.pathname === "/allpolls";

  return (
    <Navbar expand="lg" bg="body-tertiary" className="mt-2">
      <Container>
        <NavLink to="/">
          <Navbar.Brand>
            <img src={mypoll} alt="Logo" style={{ width: "90px" }} />
          </Navbar.Brand>
        </NavLink>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-lg-0 mx-2 mb-1"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link as={NavLink} to="/allpolls">
              All Polls
            </Nav.Link>
            {isLoggedIn() && (
              <Nav.Link as={NavLink} to="/mypolls">
                My Polls
              </Nav.Link>
            )}
          </Nav>
          {shouldRenderSearch && (
            <Form>
              <Form.Control
                type="search"
                placeholder="Search here"
                className="me-3 mt-sm-2"
                aria-label="Search"
                value={searchQuery}
                style={{
                  width: "40vw",
                  height: "3rem",
                  borderRadius: "30px",
                }}
                onChange={handleSearchChange}
              />
            </Form>
          )}
          {isLoggedIn() ? (
            <>
              <span style={{ color: "gray" }}>
                {signUpUserDetails.userName}
              </span>
              <Button className="ms-1 logout-btn" onClick={handleLogout}>
                Logout
                <img className="mx-1" src={logoutIcon} alt="Logout Icon" />
              </Button>
            </>
          ) : location.pathname !== "/login" ? (
            <Button className="logout-btn" onClick={() => navigate("/login")}>
              Login <img className="mx-1" src={logoutIcon} alt="Logout Icon" />
            </Button>
          ) : null}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
