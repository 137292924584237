import React, { useState, useEffect } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import filterIcon from "../../assets/svgs/filterIcon.svg";
import useStore from "../../store/store";
import formatDate from "../../utilities/dateFormate";
import Select from "react-select";

function DashboardButtons({ data }) {
  const categories = [...new Set(data.polls.map((poll) => poll.category))];
  const endDates = [
    ...new Set(
      data.polls.map((poll) => {
        return formatDate(poll.endDate);
      })
    ),
  ];
  const allTags = [...new Set(data.polls.flatMap((poll) => poll.tag))];

  const lastFourTags = allTags.slice(-6); // Get the last four tags

  // store data
  const { setSelectedCategory, setSelectedEndDate, setTag } = useStore();

  // States for Category and endDate
  const [selectedCategory, setSelectedCategoryLocal] = useState("");
  const [selectedEndDate, setSelectedEndDateLocal] = useState("");
  const [selectedTag, setSelectedTag] = useState([]);

  const handleCategoryChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : "";
    setSelectedCategoryLocal(selectedValue === "All" ? "" : selectedValue);
  };

  const handleEndDateChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : "";
    setSelectedEndDateLocal(selectedValue === "All" ? "" : selectedValue);
  };

  const handleTagClick = (tag) => {
    if (selectedTag.includes(tag)) {
      setSelectedTag((prevSelectedTag) =>
        prevSelectedTag.filter((selectedTag) => selectedTag !== tag)
      );
    } else {
      setSelectedTag((prevSelectedTag) => [...prevSelectedTag, tag]);
    }

    setTag(tag);
  };

  useEffect(() => {
    setSelectedCategory(selectedCategory);
    setSelectedEndDate(selectedEndDate);
    setTag(selectedTag);
  }, [selectedCategory, selectedEndDate, selectedTag]);

  return (
    <Container>
      <div className="mt-3 justify-content-center align-items-center d-flex justify-content-between">
        <div className="d-flex flex-inline">
          <img src={filterIcon} alt="Filter Icon" />
          <div>
            <Select
              className="me-1"
              isSearchable={true}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "150px",
                  fontSize: "small",
                }),
              }}
              onChange={handleCategoryChange}
              value={
                selectedCategory
                  ? categories.find(
                      (category) => category.value === selectedCategory
                    )
                  : null
              }
              placeholder="Select category"
              options={[
                { value: "All", label: "All" },
                ...categories.map((category) => ({
                  value: category,
                  label: category,
                })),
              ]}
            />
          </div>

          <div>
            <Select
              className="me-1"
              isSearchable={true}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "150px",
                  fontSize: "small",
                }),
              }}
              onChange={handleEndDateChange}
              value={
                selectedEndDate
                  ? endDates.find(
                      (endDate) => endDate.value === selectedEndDate
                    )
                  : null
              }
              placeholder="Select end date"
              options={[
                { value: "All", label: "All" },
                ...endDates.map((endDate) => ({
                  value: endDate,
                  label: endDate,
                })),
              ]}
            />
          </div>
          {lastFourTags.map((tag, index) => (
            <span
              key={index}
              className={`mx-1 border p-1 rounded ${
                selectedTag.includes(tag) ? "bgColor" : "backGround"
              }`}
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </span>
          ))}
        </div>
        <Button as={Link} to="/createpoll" className="btn-secondary btn">
          + Create poll
        </Button>
      </div>
    </Container>
  );
}

export default DashboardButtons;
