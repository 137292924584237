import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import useStore from "../../store/store";

function Cards() {
  const { totalPolls, totalCategories, totalUsers } = useStore();

  const cardData = [
    { title: "Polls", data: totalPolls, className: "card-1" },
    { title: "Voters", data: totalUsers, className: "card-2" },
    { title: "Categories", data: totalCategories, className: "card-3" },
  ];

  return (
    <Container>
      <Row>
        {cardData.map((item, index) => (
          <Col key={index} xs={12} sm={4} className="my-2">
            <Card className={`text-center text-light ${item.className}`}>
              <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <Card.Text>{item.data}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Cards;
