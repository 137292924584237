import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import MyPollNavbar from "./components/Navbar";
import Home from "./pages/Home";
import Login from "./pages/login";
import AllPolls from "./pages/allPolls";
import MyPolls from "./pages/myPolls";
import CreatePoll from "./pages/createPoll";
import VoteSection from "./pages/voteSection";
import Chart from "./pages/resultChart";

function App() {
  return (
    <>
      <header>
        <MyPollNavbar />
      </header>
      <main>
        <Routes>
          <Route exact="true" path="/" element={<Home />} />
          <Route exact="true" path="/allpolls" element={<AllPolls />} />
          <Route exact="true" path="/login" element={<Login />} />
          <Route
            exact="true"
            path="/mypolls"
            element={
              <ProtectedRoutes>
                <MyPolls />
              </ProtectedRoutes>
            }
          />
          <Route
            exact="true"
            path="/createpoll"
            element={
              <ProtectedRoutes>
                <CreatePoll />
              </ProtectedRoutes>
            }
          />
          <Route
            exact="true"
            path="/vote"
            element={
              <ProtectedRoutes>
                <VoteSection />
              </ProtectedRoutes>
            }
          />
          <Route
            exact="true"
            path="/chart"
            element={
              <ProtectedRoutes>
                <Chart />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </main>
    </>
  );
}

export default App;

function ProtectedRoutes({ children }) {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" replace={true} />;
  } else {
    return children;
  }
}
