import { Row, Col, Button, OverlayTrigger } from "react-bootstrap";
import publicIcon from "../../assets/svgs/publicIcon.svg";
import privateIcon from "../../assets/svgs/privateIcon.svg";
import infoIcon from "../../assets/svgs/infoIcon.svg";
import formatDate from "../../utilities/dateFormate";

function PollRow({ poll, handleVoteClick, handleShow, renderTooltip }) {
  return (
    <Row
      key={poll._id}
      className="border rounded align-items-center justify-content-center"
      style={{
        fontSize: "13px",
        height: "auto",
        maxHeight: "4rem",
        marginBottom: ".9rem",
      }}
    >
      <Col className="col-1">
        {poll.privacy === "private" ? (
          <img src={privateIcon} alt="Private Poll Icon" />
        ) : (
          <img src={publicIcon} alt="Public Poll Icon" />
        )}
      </Col>
      <Col
        className="d-flex align-items-center col-4 me-1"
        style={{
          alignContent: "center !important",
          paddingTop: "12px",
          textOverflow: "ellipsis",
        }}
      >
        <p>{poll.name}</p>
      </Col>
      <Col
        style={{
          alignContent: "center !important",
          paddingTop: "12px",
          textOverflow: "ellipsis",
        }}
      >
        <p>{poll.category}</p>
      </Col>
      <Col
        style={{
          alignContent: "center !important",
          paddingTop: "12px",
        }}
      >
        <p>{formatDate(poll.endDate)}</p>
      </Col>
      <Col className="d-flex justify-content-between">
        <Button className="btn-veiw" onClick={() => handleVoteClick(poll._id)}>
          View
        </Button>

        <OverlayTrigger
          placement="top"
          overlay={(props) => renderTooltip(props, poll)}
        >
          <span onClick={() => handleShow(poll)}>
            <img src={infoIcon} alt="info Icon" style={{ cursor: "pointer" }} />
          </span>
        </OverlayTrigger>
      </Col>
    </Row>
  );
}

export default PollRow;
