import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Spinner, Card, Button } from "react-bootstrap";
import axios from "axios";
import PollCard from "./pollCard";
import useStore from "../../store/store";
import backArrow from "../../assets/svgs/back.svg";

const chartAPI = `${process.env.REACT_APP_API_URL}poll`;

export default function Chart() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pollId = queryParams.get("chart");
  const [poll, setPoll] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { signUpUserDetails } = useStore();

  const fetchPoll = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Authorization token not found");
        return;
      }

      const response = await axios.post(
        chartAPI,
        {
          poll_id: pollId,
          chartSection: true,
          cnic: signUpUserDetails.cnic,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.status === 200) {
        setPoll(response.data);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error?.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchPoll();
  }, []);

  if (!poll && !errorMessage) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "20rem" }}
      >
        <Spinner animation="grow" />
      </Container>
    );
  }

  const handleVoteClick = (pollId) => {
    navigate(`/vote/?vote=${pollId}`);
  };

  const chartData =
    poll?.options?.map((option) => ({
      name: option.option,
      votes: option.score,
    })) || [];

  const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff6666", "#00b8a9"];

  return (
    <Container className="mt-5">
      {!errorMessage && (
        <PollCard poll={poll} chartData={chartData} colors={colors} />
      )}
      {errorMessage && (
        <Card>
          <Card.Body>
            <Button
              className="pe-4 border-0"
              size="sm"
              onClick={() => handleVoteClick(pollId)}
            >
              <img className="me-2" src={backArrow} alt="arrow image" />
              Back to poll
            </Button>
            <Card.Text className="text-center text-danger">
              {errorMessage}
            </Card.Text>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
