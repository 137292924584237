import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Card,
  Spinner,
  Alert,
} from "react-bootstrap";
import resultIcon from "../../assets/svgs/chart-bar.svg";
import shareIcon from "../../assets/svgs/share.svg";
import useStore from "../../store/store";
import privateIcon from "../../assets/svgs/privateIcon.svg";
import publicIcon from "../../assets/svgs/publicIcon.svg";
import ShareModal from "../../components/common/shareModal";
import backArrow from "../../assets/svgs/back.svg";

const API_URL = process.env.REACT_APP_API_URL;
const POLL_URL = `${API_URL}poll`;
const VOTE_URL = `${API_URL}auth/vote`;

export default function VoteSection() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const pollId = queryParams.get("vote");
  const { signUpUserDetails } = useStore();

  const [poll, setPoll] = useState(null);
  const [selectedOption, setSelectedOption] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  const fetchPoll = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Authorization token not found");
        return;
      }

      const response = await axios.post(
        POLL_URL,
        { poll_id: pollId, voteSection: true, cnic: signUpUserDetails.cnic },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response && response.status === 200) {
        setPoll(response.data);
      }
    } catch (error) {
      setErrorMessage(error.response?.data || "An error occurred");
    }
  };

  useEffect(() => {
    fetchPoll();
  }, []);

  const vote = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("Authorization token not found");
        return;
      }

      const response = await axios.post(
        VOTE_URL,
        {
          poll_id: poll._id,
          option: selectedOption.toString(),
          cnic: signUpUserDetails.cnic,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setAlertMessage("Successfully Voted");
        setTimeout(() => setAlertMessage(""), 1500);
      } else if (response.status === 202 || response.status === 203) {
        setAlertMessage(response.data);
        setTimeout(() => setAlertMessage(""), 1500);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    vote();
  };

  const handleOptionChange = (e) => {
    setSelectedOption(parseInt(e.target.value));
  };

  const handleChartClick = () => {
    navigate(`/chart/?chart=${pollId}`);
  };

  const toggleModal = () => {
    setIsShareModalVisible(!isShareModalVisible);
  };

  if (!poll && !errorMessage) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "20rem" }}
      >
        <Spinner animation="grow" />
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      {alertMessage && (
        <Alert className="text-center createAlert">{alertMessage}</Alert>
      )}
      {!errorMessage && (
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className="mt-5 bg-light" style={{ border: "none" }}>
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <Card.Title>{poll?.name}</Card.Title>

                  <div className="d-flex align-items-center">
                    <p className="me-3" style={{ all: "unset", color: "gray" }}>
                      created by {poll?.pollCreatorName}
                    </p>
                    <img
                      src={
                        poll?.privacy === "private" ? privateIcon : publicIcon
                      }
                      alt="Privacy Icon"
                    />
                  </div>
                </div>

                <Form onSubmit={handleFormSubmit} className="mt-5">
                  <Form.Group>
                    {poll?.options &&
                      poll.options.map((option, index) => (
                        <Form.Check
                          key={index}
                          type="radio"
                          label={option.option}
                          name="pollOption"
                          id={`option-${index}`}
                          value={index}
                          checked={selectedOption === index}
                          onChange={handleOptionChange}
                        />
                      ))}
                  </Form.Group>
                  <Button
                    className="me-1 mt-5"
                    size="sm"
                    onClick={() => navigate("/mypolls")}
                  >
                    <img className="me-2" src={backArrow} alt="arrow image" />
                    Back
                  </Button>
                  <Button className="w-25 mt-5" size="sm" type="submit">
                    Vote
                  </Button>

                  <Button
                    className="mx-1 mt-5"
                    size="sm"
                    onClick={handleChartClick}
                  >
                    <img src={resultIcon} alt="result icon" />
                    Show results
                  </Button>
                  <Button className="px-3 mt-5" size="sm" onClick={toggleModal}>
                    <img src={shareIcon} alt="Share Icon" />
                    Share
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {errorMessage && (
        <Card>
          <Card.Body>
            <Card.Text className="text-center text-danger">
              {errorMessage}
            </Card.Text>

            {errorMessage === "It's a private poll" && (
              <Button
                className="me-1 mt-5"
                size="sm"
                onClick={() => navigate("/mypolls")}
              >
                <img className="me-2" src={backArrow} alt="arrow image" />
                Back
              </Button>
            )}

            {errorMessage === "Poll ended" && (
              <>
                <Button
                  className="me-1 mt-5"
                  size="sm"
                  onClick={() => navigate("/mypolls")}
                >
                  <img className="me-2" src={backArrow} alt="arrow image" />
                  Back
                </Button>
                <Button
                  className="me-1 mt-5"
                  size="sm"
                  onClick={handleChartClick}
                >
                  <img src={resultIcon} alt="result icon" />
                  Show results
                </Button>
              </>
            )}

            {errorMessage === "Poll not Started" && (
              <>
                <Button
                  className="me-1 mt-5"
                  size="sm"
                  onClick={() => navigate("/mypolls")}
                >
                  <img className="me-2" src={backArrow} alt="arrow image" />
                  Back
                </Button>
                <Button className="px-3 mt-5" size="sm" onClick={toggleModal}>
                  <img src={shareIcon} alt="Share Icon" />
                  Share
                </Button>
              </>
            )}
          </Card.Body>
        </Card>
      )}

      {isShareModalVisible && <ShareModal onClose={toggleModal} />}
    </Container>
  );
}
