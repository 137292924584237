import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Spinner } from "react-bootstrap";
import DashboardButtons from "../../components/common/pollsSearching";
import Cards from "../../components/common/pollsCard";
import PollsData from "../../components/common/pollsData";
import useStore from "../../store/store";

const API_URL = process.env.REACT_APP_API_URL;

export default function AllPolls() {
  // State variables
  const [polls, setPolls] = useState([]);
  const [users, setUsers] = useState([]);
  const { setTotalUsers } = useStore();

  const fetchData = async () => {
    try {
      const [pollsResponse, usersResponse] = await Promise.all([
        axios.get(`${API_URL}polls`),
        axios.get(`${API_URL}users`),
      ]);

      // Sort the polls data by endDate in ascending order
      const sortedPolls = pollsResponse?.data?.sort(
        (a, b) => new Date(a.endDate) - new Date(b.endDate)
      );

      setPolls(sortedPolls);
      setUsers(usersResponse.data);
      setTotalUsers(usersResponse.data.length || 0);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (polls.length === 0) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "20rem" }}
      >
        <Spinner animation="grow" />
      </Container>
    );
  }

  return (
    <Container>
      <div>
        <DashboardButtons data={{ polls }} />
        <Cards data={users} />
      </div>
      <div>
        <PollsData data={{ polls }} />
      </div>
    </Container>
  );
}
