import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialState = {
  signUpUserDetails: [],
  totalPolls: 0,
  totalCategories: 0,
  totalUsers: 0,
  searchQuery: "",
  selectedCategory: "",
  selectedEndDate: "",
  tag: [],
};

const useStore = create(
  persist(
    (set) => ({
      ...initialState,
      setSignUpUserDetails: ({ cnic, token, userName }) =>
        set((state) => ({
          ...state,
          signUpUserDetails: { token, cnic, userName },
        })),

      setTotalPolls: (pollsNumber) => set({ totalPolls: pollsNumber }),
      setTotalCategories: (categoriesNumber) =>
        set({ totalCategories: categoriesNumber }),
      setTotalUsers: (usersNumber) => set({ totalUsers: usersNumber }),
      setSearchQuery: (query) => set({ searchQuery: query }),
      setSelectedCategory: (category) => set({ selectedCategory: category }),
      setSelectedEndDate: (endDate) => set({ selectedEndDate: endDate }),
      setTag: (tag) => set({ tag: tag }),
    }),
    {
      name: "signUpdetails",
    }
  )
);

export default useStore;
