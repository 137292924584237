import React from "react";
import { Modal } from "react-bootstrap";
import formatDate from "../../utilities/dateFormate";

function PollModal({ showModal, handleClose, selectedPoll }) {
  const renderPollDetail = (label, value) => (
    <p>
      <strong>{label}: </strong>
      <span className="float-end">{value}</span>
    </p>
  );

  return (
    <Modal show={showModal} onHide={handleClose} size="sm">
      <Modal.Header>
        <Modal.Title>Poll details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedPoll && (
          <div className="mx-2">
            {renderPollDetail("privacy", selectedPoll.privacy)}
            {renderPollDetail("category", selectedPoll.category)}
            {renderPollDetail("sub category", selectedPoll.subcategory)}
            {renderPollDetail("start date", formatDate(selectedPoll.startDate))}
            {renderPollDetail("end date", formatDate(selectedPoll.endDate))}
            {renderPollDetail("casted votes", selectedPoll.voters)}
            {selectedPoll?.privacy === "private" &&
              renderPollDetail("voters", selectedPoll.votersList)}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default PollModal;
