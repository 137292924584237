import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Spinner } from "react-bootstrap";
import DashboardButtons from "../../components/common/pollsSearching";
import Cards from "../../components/common/pollsCard";
import PollsData from "../../components/common/pollsData";

const MyPolls = () => {
  // State variables
  const [polls, setPolls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const myPolls_URL = `${process.env.REACT_APP_API_URL}auth/user/polls`;

  const fetchPolls = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Authorization token not found");
        return;
      }

      const response = await axios.get(myPolls_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const sortedPolls = response.data.sort(
          (a, b) => new Date(a.endDate) - new Date(b.endDate)
        );

        setPolls(sortedPolls);
      } else {
        console.error("Failed to fetch polls");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPolls();
  }, []);

  if (isLoading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "20rem" }}
      >
        <Spinner animation="grow" />
      </Container>
    );
  }

  return (
    <Container>
      <div>
        <DashboardButtons data={{ polls }} />
        <Cards />
      </div>
      <div>
        <PollsData data={{ polls }} />
      </div>
    </Container>
  );
};

export default MyPolls;
