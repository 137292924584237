import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Card,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
} from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";
import Papa from "papaparse";
import deleteOption from "../../assets/svgs/Vector.svg";
import axios from "axios";
import useStore from "../../store/store";

const newPollEndpoint = `${process.env.REACT_APP_API_URL}auth/new_poll`;

export default function CreatePoll() {
  const { signUpUserDetails } = useStore();

  // State variables
  const [options, setOptions] = useState([
    { id: 1, value: "", removable: false },
    { id: 2, value: "", removable: false },
  ]);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [question, setQuestion] = useState("");
  const [privacy, setPrivacy] = useState("public");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isEndDateValid, setIsEndDateValid] = useState(false);
  const [resultVisible, setResultVisible] = useState("no");
  const [resultVisibility, setResultVisibility] = useState("");
  const [votersList, setVotersList] = useState([]);
  const [selected, setSelected] = useState([signUpUserDetails.cnic]);
  const [tag, setTag] = useState([]);
  const [tagsError, setTagsError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const trimmedQuestion = question.trim();
    const optionsData = options.map((option) => option.value);

    // Check if any required fields are empty
    if (
      category === "" ||
      subcategory === "" ||
      tag === "" ||
      question === "" ||
      options.some((option) => option.value.trim() === "") ||
      startDate === "" ||
      endDate === "" ||
      !isEndDateValid
    ) {
      setFormSubmitted(true);
      return; // Do not submit the form if required fields are empty
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("User not Authorized!");
        return;
      }

      const response = await axios.post(
        newPollEndpoint,
        {
          name: trimmedQuestion,
          options: JSON.stringify(optionsData),
          category,
          subcategory,
          privacy,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          resultVisibility,
          votersList,
          resultVisible,
          tag,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setAlertMessage("Poll Created Successfully");
        setTimeout(() => setAlertMessage(""), 1500);
      } else {
        setAlertMessage("Failed to create poll");
        setTimeout(() => setAlertMessage(""), 1500);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    resetForm();
  };

  // Reset form fields
  const resetForm = () => {
    setOptions([
      { id: 1, value: "", removable: false },
      { id: 2, value: "", removable: false },
    ]);
    setCategory("");
    setSubcategory("");
    setQuestion("");
    setStartDate("");
    setEndDate("");
    setTag([]);
    setSelected([signUpUserDetails?.cnic]);
    document.getElementById("new-poll-form").reset();
  };

  // Handle category change
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  // Handle subcategory change
  const handleSubcategoryChange = (event) => {
    setSubcategory(event.target.value);
  };

  // Handle Question Change
  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  // Handle option text change
  const handleOptionChange = (event, optionId) => {
    const updatedOptions = options.map((option) =>
      option.id === optionId ? { ...option, value: event.target.value } : option
    );
    setOptions(updatedOptions);
  };

  // Add a new option
  const handleMoreOptions = () => {
    const newOption = { id: options.length + 1, value: "", removable: true };
    setOptions([...options, newOption]);
  };

  // Remove an option
  const handleRemoveOption = (optionId) => {
    const updatedOptions = options.filter((option) => option.id !== optionId);
    setOptions(updatedOptions);
  };

  // Handle start date change
  const handleStartDateChange = (event) => {
    const localDateString = event.target.value;
    setStartDate(localDateString);
  };

  // Handle end date change
  const handleEndDateChange = (event) => {
    const localDateString = event.target.value;
    console.log(localDateString);
    setEndDate(localDateString);
    setIsEndDateValid(
      startDate && localDateString && startDate < localDateString
    );
  };

  const validateInputTag = (newTag) => {
    if (tag.length >= 3) {
      setTagsError("You can only enter up to 3 tags.");
      return false;
    }

    if (tag.includes(newTag)) {
      setTagsError("Tag already exists.");
      return false;
    } else {
      setTagsError("");
      return true;
    }
  };

  // Function to remove duplicates from an array
  const removeDuplicates = (array) => {
    return [...new Set(array)];
  };

  // Handle File Change for Voters List
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileName = file.name.toLowerCase();
      if (fileName.endsWith(".csv")) {
        setErrorMessage(""); // Clear any previous error messages

        Papa.parse(file, {
          complete: (results) => {
            const pattern1 = /^\d{5}-\d{7}-\d$/;
            const pattern2 = /^\d{13}$/;
            const newVoters = results.data
              .map((row) => row[0].replace(/\s+/g, ""))
              .filter((value) => pattern1.test(value) || pattern2.test(value))
              .map((value) =>
                pattern1.test(value) ? value.replace(/-/g, "") : value
              );

            // Remove duplicates from the newly added CNICs
            const uniqueNewVoters = removeDuplicates(newVoters);

            // Merge with existing selected CNICs
            const mergedCNICs = removeDuplicates([
              ...selected,
              ...uniqueNewVoters,
            ]);
            setSelected(mergedCNICs);
          },
        });
      } else {
        setErrorMessage("Please select a valid .csv file.");
      }
    }
  };

  // Validating the voters list
  const isInputValid = (input, existingTags) => {
    const isNumber = /^\d{13}$/.test(input);
    const isUnique = !existingTags.includes(input);

    if (!isNumber || !isUnique) {
      setErrorMessage("Please enter a unique 13-digit cnic number.");
    } else {
      setErrorMessage("");
    }

    return isNumber && isUnique;
  };

  // Handle result visibility change
  const handleResultVisibilityChange = (val) => {
    setResultVisibility(val);
  };

  // Check if required fields are empty
  const areFieldsEmpty = () => {
    return (
      category === "" ||
      subcategory === "" ||
      tag === "" ||
      question === "" ||
      options.some((option) => option.value.trim() === "") ||
      startDate === "" ||
      endDate === "" ||
      !isEndDateValid
    );
  };

  useEffect(() => {
    const firstRtiTag = document.querySelector(".rti--tag");
    if (firstRtiTag) {
      firstRtiTag.classList.add("disable-input");
    }
    setVotersList(selected);
  }, [selected]);

  return (
    <Container className="mb-5">
      {alertMessage && (
        <Alert className="text-center createAlert">{alertMessage}</Alert>
      )}
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="text-center">
            <h1>Create a poll</h1>
            <p>Complete the below fields to create a poll</p>
          </div>
          <Card>
            <Form id="new-poll-form" onSubmit={handleSubmit} className="p-3">
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-1">
                    <Form.Control
                      className="inputStyle"
                      type="text"
                      id="category"
                      name="category"
                      value={category}
                      onChange={handleCategoryChange}
                      placeholder="Enter category..."
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-1">
                    <Form.Control
                      className="inputStyle"
                      type="text"
                      id="subcategory"
                      name="subcategory"
                      value={subcategory}
                      onChange={handleSubcategoryChange}
                      placeholder="Enter subcategory..."
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-1">
                <Form.Label className="mb-0">Question</Form.Label>
                <Form.Control
                  className="inputStyle"
                  type="text"
                  id="name"
                  name="name"
                  value={question}
                  placeholder="Enter your question here"
                  onChange={handleQuestionChange}
                />
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label className="mb-0">Answer options</Form.Label>
                {options.map((option) => (
                  <InputGroup key={option.id} className="my-2">
                    <Form.Control
                      className="inputStyle"
                      type="text"
                      placeholder={`Option ${option.id}`}
                      value={option.value}
                      onChange={(event) => handleOptionChange(event, option.id)}
                    />
                    {option.removable && (
                      <Button
                        style={{ border: "none", background: "none" }}
                        onClick={() => handleRemoveOption(option.id)}
                      >
                        <img src={deleteOption} alt="delete option" />
                      </Button>
                    )}
                  </InputGroup>
                ))}
              </Form.Group>

              <Button className="mx-1 btn-primary" onClick={handleMoreOptions}>
                + Add Options
              </Button>

              <hr />

              <h5 className="text-center">Settings</h5>
              <h6>Schedule a poll</h6>
              <Form.Group className="mb-1 px-2">
                <Form.Label className="mb-0">Start date</Form.Label>
                <Form.Control
                  className="inputStyle"
                  type="datetime-local"
                  value={startDate}
                  onChange={handleStartDateChange}
                  min={new Date().toISOString().split(".")[0].slice(0, -3)}
                />
              </Form.Group>

              <Form.Group className="mb-1 px-2">
                <Form.Label className="mb-0">End date</Form.Label>
                <Form.Control
                  className="inputStyle"
                  type="datetime-local"
                  value={endDate}
                  onChange={handleEndDateChange}
                  min={startDate} // Disable dates before the start date
                />
                {!isEndDateValid && (
                  <p className="text-danger">
                    End date must be after the start date
                  </p>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>Enter Tags</Form.Label>
                <TagsInput
                  value={tag}
                  onChange={setTag}
                  name="tags"
                  placeholder="Enter tags..."
                  beforeAddValidate={validateInputTag}
                />
                {tagsError && <p className="text-danger">{tagsError}</p>}
                <em className="text-primary mb-5">
                  Press enter or comma to add a new tag number
                </em>
              </Form.Group>

              <Form.Group className="mb-1 px-2 mt-3">
                <Form.Label className="mb-2">Poll privacy</Form.Label>
                <div>
                  <Form.Check
                    className="ms-2"
                    type="radio"
                    id="public"
                    name="privacy"
                    label="Public"
                    value="public"
                    checked={privacy === "public"}
                    onChange={() => setPrivacy("public")}
                  />
                  <Form.Check
                    className="ms-2"
                    type="radio"
                    id="private"
                    name="privacy"
                    label="Private"
                    value="private"
                    checked={privacy === "private"}
                    onChange={() => setPrivacy("private")}
                  />
                </div>
              </Form.Group>
              {privacy === "private" && (
                <div className="mt-4">
                  <h6>Add Voters</h6>
                  <Form.Group className="my-2 w-50">
                    <Form.Control
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                    />
                  </Form.Group>

                  <div className="disable-input">
                    <TagsInput
                      value={selected}
                      onChange={setSelected}
                      name="cnics"
                      placeholder="Enter cnics..."
                      beforeAddValidate={isInputValid}
                    />
                  </div>
                  <em className="text-primary">
                    Press enter or comma to add a new cnic number
                  </em>
                  {errorMessage && (
                    <p className="text-danger">{errorMessage}</p>
                  )}
                </div>
              )}
              <Form.Group className="mt-3 px-2">
                <Form.Label>Result Visibility</Form.Label>
                <div>
                  <Form.Check
                    className="ms-2"
                    type="radio"
                    id="no"
                    name="resultVisible"
                    label="No"
                    value="no"
                    checked={resultVisible === "no"}
                    onChange={() => setResultVisible("no")}
                  />
                  <Form.Check
                    className="ms-2"
                    type="radio"
                    id="yes"
                    name="resultVisible"
                    label="Yes"
                    value="yes"
                    checked={resultVisible === "yes"}
                    onChange={() => setResultVisible("yes")}
                  />
                </div>
              </Form.Group>

              {resultVisible === "yes" && (
                <Form.Group className="mt-3 px-2">
                  <Form.Label>Select Option</Form.Label>
                  <div>
                    <ToggleButtonGroup
                      type="radio"
                      value={resultVisibility}
                      name="options"
                      onChange={handleResultVisibilityChange}
                    >
                      <ToggleButton id="tbg-btn-1" value={"during"}>
                        During
                      </ToggleButton>
                      <ToggleButton id="tbg-btn-2" value={"after"}>
                        After
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </Form.Group>
              )}

              <Button
                className="mt-2 btn-secondary float-end"
                type="submit"
                disabled={areFieldsEmpty()}
              >
                Submit
              </Button>
              {formSubmitted && areFieldsEmpty() && (
                <p className="text-danger">
                  Please fill in all required fields.
                </p>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
