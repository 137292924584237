import React from "react";
import { Card, Container } from "react-bootstrap";

function Home() {
  return (
    <Container className="d-flex justify-content-center mt-5">
      <Card className="w-50 text-center">
        <Card.Body>
          <Card.Title className="fs-1">MyPoll</Card.Title>
          <Card.Text className="mt-2">
            A web based voting app that shows poll results in real time. MyPoll
            Box is a web-based application that enables you to create ranking,
            rating, and poll voting contests and view the results in real time.
            Your audience can participate live on their mobile phones, tablets
            and desktops. There is nothing to download or install.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Home;
