function addLeadingZero(num) {
  return num < 10 ? "0" + num : num;
}

function formatDate(dateString) {
  let date = new Date(dateString);
  let formattedDate = `${addLeadingZero(date.getMonth() + 1)}.${addLeadingZero(
    date.getDate()
  )}.${date.getFullYear()} ${addLeadingZero(date.getHours())}:${addLeadingZero(
    date.getMinutes()
  )}`;
  return formattedDate;
}

export default formatDate;
