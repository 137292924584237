import React, { useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from "recharts";
import shareIcon from "../../assets/svgs/share.svg";
import privateIcon from "../../assets/svgs/privateIcon.svg";
import publicIcon from "../../assets/svgs/publicIcon.svg";
import backArrow from "../../assets/svgs/back.svg";
import { useNavigate } from "react-router-dom";
import ShareModal from "../../components/common/shareModal";

function PollCard({ poll, chartData, colors }) {
  const navigate = useNavigate();
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  const handleVoteClick = (pollId) => {
    navigate(`/vote/?vote=${pollId}`);
  };

  const toggleModal = () => {
    setIsShareModalVisible(!isShareModalVisible);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <Card.Title>{poll.name}</Card.Title>
            <img
              src={poll?.privacy === "private" ? privateIcon : publicIcon}
              alt="Privacy Icon"
            />
          </div>
          <div
            className="ms-4"
            style={{ fontSize: "0.6rem", color: "#737373" }}
          >
            Total votes
          </div>
          <div className="ms-4">{poll?.voters}</div>

          <Row className="mt-5">
            <Col xs={12} md={6} className="mb-3 mt-md-5">
              {chartData.map((option, index) => (
                <div key={index} className="d-flex mb-1 align-items-center">
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: colors[index],
                      marginRight: "8px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex align-items-center">
                      <p className="m-0">{option.name}</p>
                    </div>
                    <div>
                      <p className="m-0 ms-2">{option?.votes}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Col>
            <Col xs={12} md={6}>
              <div className="d-flex justify-content-center">
                <ResponsiveContainer width="100%" height={250}>
                  <PieChart>
                    <Pie
                      data={chartData}
                      dataKey="votes"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={90}
                      label={(entry) => entry.name}
                      labelLine={false}
                      startAngle={90}
                    >
                      {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                style={{ width: "10rem" }}
                onClick={() => handleVoteClick(poll._id)}
              >
                <img className="me-2" src={backArrow} alt="arrow image" />
                Back to poll
              </Button>
              <Button
                className="ms-2"
                style={{ width: "10rem", cursor: "pointer" }}
                onClick={toggleModal}
              >
                <img src={shareIcon} alt="share icon" /> Share
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isShareModalVisible && <ShareModal onClose={toggleModal} />}
    </>
  );
}

export default PollCard;
