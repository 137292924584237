import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import homeImage from "../../assets/svgs/homeImage.svg";
import useStore from "../../store/store";

const signAuthURL = `${process.env.REACT_APP_API_URL}auth`;
const signCollectURL = `${process.env.REACT_APP_API_URL}collect`;

function Login() {
  const { setSignUpUserDetails } = useStore();

  useEffect(() => {
    const container = document.querySelector(".meraid-auth-container");
    if (container && !container.shadowRoot) {
      window.meraIDAuth({
        container: ".meraid-auth-container",
        authApi: { method: "POST", url: signAuthURL },
        collectApi: { method: "POST", url: signCollectURL },
        onSuccess: async (data) => {
          localStorage.setItem("token", data?.token);

          setSignUpUserDetails({
            cnic: data?.user?.cnic,
            userName: data?.user?.name,
            token: data?.token,
          });

          window.location.href = "/allpolls";
        },
      });
    }
  }, [setSignUpUserDetails]);

  return (
    <Container>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img
          src={homeImage}
          alt="Home page image"
          className="loginImage"
          style={{ width: "600px", height: "390px" }}
          loading="lazy"
        />
        <div className="mt-3 text-center">
          <h3>Secure Login</h3>
          <p>Ensuring trustworthy polls through secure login</p>
          <div className="meraid-auth-container"></div>
        </div>
      </div>
    </Container>
  );
}

export default Login;
