import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import googleIcon from "../../assets/svgs/google.svg";
import facebookIcon from "../../assets/svgs/facebookIcon.svg";
import windowIcon from "../../assets/svgs/windowIcon.svg";
import copyIcon from "../../assets/svgs/copyIcon.svg";

function ShareModal({ onClose }) {
  const currentUrl = window.location.href;
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(currentUrl);
      setIsLinkCopied(true);
      setTimeout(() => setIsLinkCopied(false), 1000);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      dialogClassName="modal-center"
      centered
      size="lg"
    >
      <Modal.Body>
        <Container>
          {isLinkCopied && <div className="text-center mb-4">Link copied</div>}
          <div className="text-center mb-5">
            <img className="mx-2" src={googleIcon} alt="Google Icon" />
            <img className="mx-2" src={facebookIcon} alt="Facebook Icon" />
            <img className="mx-2" src={windowIcon} alt="Windows Icon" />
          </div>

          <div className="mt-5 d-flex justify-content-between align-items-center border-radius">
            <p style={{ all: "unset" }} className="ms-2">
              {currentUrl}
            </p>
            <img
              src={copyIcon}
              alt="Copy Icon"
              onClick={handleCopyClick}
              style={{ cursor: "pointer" }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ShareModal;
